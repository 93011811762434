import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled, keyframes } from '@mui/material/styles';

// Define slide-in and slide-out animations
const slideIn = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
`;

const slideOut = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(100%); }
`;

const StyledPaper = styled(Paper)(({ theme, open }) => ({
    backgroundColor: '#1A1A1A',
    color: 'white',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
    opacity: 0.9,
    position: 'relative',
    width: '350px', // Adjust width if needed
    minHeight: '60px', // Adjust height if needed
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.primary.main}`, // Add a border with primary color
    animation: open ? `${slideIn} 0.5s ease-out` : `${slideOut} 0.5s ease-in`,
    zIndex: 1300, // Ensure it's above other content
}));
  
const PopperComponent = ({ type, text, open, onClose }) => {
  const [show, setShow] = useState(open);

  useEffect(() => {
    if (open) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
        setTimeout(onClose, 500); // Ensure it closes after animation
      }, 2000); // Show for 2 seconds
      return () => clearTimeout(timer);
    }
  }, [open, onClose]);

  return (
    <Popper
      open={show}
      anchorEl={document.body} // Use document.body as the anchor
      placement="bottom-end"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, -190], // Adjust the offset to ensure it's within the screen
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 16, // Ensure the Popper stays within the viewport
          },
        },
      ]}
      style={{ pointerEvents: 'none' }} // Allow interaction with other elements
    >
      <StyledPaper open={show}>
        <Typography variant="body1" component="div" style={{ fontSize: '16px' }}>
          {text}
        </Typography>
        <IconButton
          onClick={onClose}
          style={{ color: 'white', position: 'absolute', top: 10, right: 10 }}
        >
          <CloseIcon />
        </IconButton>
      </StyledPaper>
    </Popper>
  );
};

PopperComponent.propTypes = {
  type: PropTypes.oneOf(['error', 'confirmation']).isRequired,
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PopperComponent;
