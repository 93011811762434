import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Home from "./views/home/home";
import NavBar from "./components/navbar";
import { Web3ModalProvider } from './providers/web3ModelProvider'


function App() {
  return (
    <Web3ModalProvider> 
      <Router>
        <NavBar />
        <div className="App" id={"scroll"}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </Web3ModalProvider>
  );
}

export default App;
