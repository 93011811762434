import { Container } from "react-bootstrap";
import React from "react";
import PropTypes from 'prop-types';

import './displayWidget.css';


const DisplayWidget = (props) => {

    const handleChange = (e) => {
        props.onChanged(e.target.value);
    };

    return (
        <section>
            <Container fluid id="home-display-widget">
                <div className="display-widget">
                    <p className="display-widget-text">Existing Balance {props.isActive}</p>
                    <div className="display-widget-form">
                        <input type="text" id="textInput" onChange={handleChange} className='display-widget-textInput' value={props.value} disabled={!props.isActive} />
                        <button className="max-button" onClick={props.onMaxClick} disabled={!props.isActive}>MAX</button>
                    </div>
                </div>
            </Container>
        </section>
    );
};

DisplayWidget.propTypes = {
    isActive: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChanged: PropTypes.func.isRequired,
    onMaxClick: PropTypes.func.isRequired,
};

export default DisplayWidget;