import React from "react";
import logo from "../assets/logo.png";

function NavBar() {
  return (
    <nav className="bg-black fixed w-full flex items-center justify-between p-3">
      <div className="flex items-center">
        <img
          alt="Viridis Logo"
          src={logo}
          className="w-16 h-12"
        />
        <span className="text-white text-lg ml-3">Viridis Migration</span>
      </div>
      <w3m-button  />
    </nav>
  );
}

export default NavBar;
