/* global BigInt */
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import './home.css';
import DisplayWidget from "./components/displayWidget";
import Step from "./components/step";
import ButtonState from "../../components/buttonState";
import { useAccount, useWaitForTransactionReceipt, useWriteContract, useReadContract } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import VRD from '../../abi/VRD.json';
import Migration from '../../abi/Migration.json';
import { ethers } from 'ethers';
import PopperComponent from './components/customDialog';

const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
const spenderAddress = process.env.REACT_APP_MIGRATION_ADDRESS;

function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [connectState, setConnectState] = useState(ButtonState.INACTIVE);
  const [approveState, setApproveState] = useState(ButtonState.INACTIVE);
  const [swapState, setSwapState] = useState(ButtonState.INACTIVE);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('error');
  const [dialogText, setDialogText] = useState('');

  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();

  const {
    writeContract: writeApproveContract,
    data: approveTxHash,
    isPending: isApprovePending
  } = useWriteContract();

  const {
    writeContract: writeSwapContract,
    data: swapTxHash,
    isPending: isSwapPending
  } = useWriteContract();

  const { isLoading: isApproveConfirming, isSuccess: isApproveConfirmed } = useWaitForTransactionReceipt({
    hash: approveTxHash,
  });

  const { isLoading: isSwapConfirming, isSuccess: isSwapConfirmed } = useWaitForTransactionReceipt({
    hash: swapTxHash,
  });

  const { data: balance } = useReadContract({
    address: tokenAddress,
    abi: VRD,
    functionName: 'balanceOf',
    args: [address],
    enabled: isConnected,
    watch: true
  });

  const { data: allowance, fetchStatus, refetch: refetchAllowance} = useReadContract({
    address: tokenAddress,
    abi: VRD,
    functionName: 'allowance',
    args: [address, spenderAddress],
    enabled: isConnected,
    watch: true
  });
  console.log(allowance, fetchStatus, refetchAllowance);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isConnected) {
      setConnectState(ButtonState.DONE);
      setApproveState(ButtonState.ACTIVE);
      setSwapState(ButtonState.INACTIVE);
    } else {
      setConnectState(ButtonState.ACTIVE);
      setApproveState(ButtonState.INACTIVE);
      setSwapState(ButtonState.INACTIVE);
    }
  }, [isConnected]);

  useEffect(() => {
    if (balance !== undefined && balance !== null) {
      setValue(ethers.formatUnits(balance, 18));
    }
  }, [balance]);

  const connectWallet = async () => {
    if (!isConnected) {
      try {
        setIsLoading(true);
        await open();
        setIsLoading(false);
      } catch (error) {
        console.error('Error connecting wallet:', error);
        setIsLoading(false);
        setDialogType('error');
        setDialogText('Error connecting wallet.');
        setDialogOpen(true);
      }
    }
  };

  useEffect(()=> {
    if (!isApproveConfirming && isApproveConfirmed) {
      window.location.reload()
    }
  }, [isApproveConfirming, isApproveConfirmed]);

  const approve = async () => {
    if (!isConnected) {
      console.error('Wallet not connected');
      return;
    }
    try {
      setApproveState(ButtonState.CONFIRMING);
      await writeApproveContract({
        abi: VRD,
        address: tokenAddress,
        functionName: 'approve',
        args: [spenderAddress, ethers.parseUnits(value.toString(), 18)],
      });
      setDialogType('confirmation');
      setDialogText(`Approval transaction sent. Tx hash: ${approveTxHash}`);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error approving tokens:', error);
      setApproveState(ButtonState.ACTIVE);
      setIsLoading(false);
      setDialogType('error');
      setDialogText(`Error approving tokens: ${error.message}`);
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const bigBalance = value ? BigInt(ethers.parseUnits(value.toString(), 18).toString()) : undefined;
      const bigAllowance = allowance ? BigInt(allowance.toString()) : undefined;

      if (isApproveConfirmed || (bigAllowance >= bigBalance && allowance !== undefined && allowance !== null)) {
        setApproveState(ButtonState.DONE);
        setSwapState(ButtonState.ACTIVE);
        setIsLoading(false);
        setDialogType('confirmation');
        setDialogText('Tokens approved successfully.');
        setDialogOpen(true);
      } else if (isApprovePending) {
        setApproveState(ButtonState.CONFIRMING);
      } else if (isConnected) {
        setApproveState(ButtonState.ACTIVE);
      } else {
        setApproveState(ButtonState.INACTIVE);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [isApproveConfirmed, isApprovePending, value, allowance, isConnected, refetchAllowance]);

  const swap = async () => {
    if (!isConnected) {
      console.error('Wallet not connected');
      return;
    }
    try {
      setSwapState(ButtonState.CONFIRMING);
      await writeSwapContract({
        abi: Migration,
        address: spenderAddress,
        functionName: 'swap',
        args: [address, ethers.parseUnits(value.toString(), 18)],
      });
      setDialogType('confirmation');
      setDialogText(`Swap transaction sent. Tx hash: ${swapTxHash}`);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error swapping tokens:', error);
      setSwapState(ButtonState.ACTIVE);
      setIsLoading(false);
      setDialogType('error');
      setDialogText(`Error swapping tokens: ${error.message}`);
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    if (isSwapConfirmed) {
      setIsLoading(false);
      setDialogType('confirmation');
      setDialogText('Tokens swapped successfully.');
      setDialogOpen(true);
    } else if (isSwapPending && !isSwapConfirmed) {
      setSwapState(ButtonState.CONFIRMING);
    } else if (isConnected) {
      setSwapState(ButtonState.ACTIVE);
    } else {
      setSwapState(ButtonState.INACTIVE);
    }
  }, [isSwapConfirmed, isSwapPending]);

  const handleMaxClick = () => {
    if (balance !== undefined && balance !== null) {
      setValue(ethers.formatUnits(balance, 18));
    }
  };

  const handleApproveOrSwap = async () => {
    if (!isConnected) {
      console.error('Wallet not connected');
      return;
    }

    const requiredAmount = BigInt(ethers.parseUnits(value.toString(), 18).toString());

    if (allowance !== undefined && allowance !== null) {
      const allowanceBigNumber = BigInt(allowance.toString());

      if (requiredAmount > allowanceBigNumber) {
        setApproveState(ButtonState.ACTIVE);
        setSwapState(ButtonState.INACTIVE);
        await approve();
      } else {
        await swap();
      }
    } else {
      await approve();
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <div className='card'>
          <h1 className="header-text">ReFi Protocol Token Claim</h1>
          <h2 className="header-sub-text">
            The $REFI token claim represent a material token swap and is
            subject to qualify participants following our general terms
          </h2>
          <DisplayWidget value={value} onChanged={setValue} onMaxClick={handleMaxClick} isActive={true} />
          <Step index={1} buttonText="Connect Wallet" buttonOnClick={connectWallet} buttonState={connectState} />
          <Step index={2} buttonText="Approve" buttonOnClick={handleApproveOrSwap} buttonState={approveState} />
          <Step index={3} buttonText="Swap Token" buttonOnClick={handleApproveOrSwap} buttonState={swapState} />
        </div>
      </Container>
      <PopperComponent
        type={dialogType}
        text={dialogText}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </section>
  );
}

export default Home;
