import React from 'react';
import PropTypes from 'prop-types';
import ButtonState from '../../../components/buttonState';
import './step.css';

const Step = (props) => {
  const isConfirming = props.buttonState === ButtonState.CONFIRMING;
  const buttonText = isConfirming ? 'Confirming...' : props.buttonText;

  return (
    <div className='step-with-divider'>
      {props.index !== 1 ? (<div className={`dotted-line ${props.buttonState}`}></div>) : null}
      <div className='step'>
        <p className={`step-text ${props.buttonState}`}>Step {props.index}</p>
        <button 
          className={`step-button ${props.buttonState}`} 
          onClick={props.buttonOnClick} 
          disabled={props.buttonState !== ButtonState.ACTIVE}
        >
          {isConfirming && <div className="spinner small-spinner"></div>}
          {buttonText}
        </button>
      </div>
    </div>
  );
};

Step.propTypes = {
  index: PropTypes.number.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonState: PropTypes.string.isRequired,
  buttonOnClick: PropTypes.func.isRequired,
};

export default Step;
